import React from 'react';
import Card from './Components/Card';
import './App.css';

const App = () => {
  return (
    <>
      <Card />
    </>
  );
};

export default App;
